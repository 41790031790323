import React from 'react';
import './App.css';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import SearchComponent from './SearchComponent'; // Import the new component
// Imports the Amplify library from 'aws-amplify' package. This is used to configure your app to interact with AWS services.
import {Amplify} from 'aws-amplify';

// Imports the default styles for the Amplify UI components. This line ensures that the authenticator looks nice out of the box.
import '@aws-amplify/ui-react/styles.css';

// Imports the awsExports configuration file generated by the Amplify CLI. This file contains the AWS service configurations (like Cognito, AppSync, etc.) specific to your project.
import awsExports from './aws-exports';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

// Configures the Amplify library with the settings from aws-exports.js, which includes all the AWS service configurations for this project.
Amplify.configure(awsExports);


function App() {
  return (
    <div className="App">
      <Authenticator>
        {({ signOut }) => (
          <main>
            <button 
              onClick={signOut} 
              className="sign-out-button"
            >
              Sign Out
            </button>
            <header className='App-header'>
              <SearchComponent />
            </header>
          </main>
        )}
      </Authenticator>
    </div>
  );
}

export default withAuthenticator(App);
