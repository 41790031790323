import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './SearchComponent.css';
import { FaHeart, FaRegHeart,FaInfoCircle } from 'react-icons/fa'; // Make sure to install react-icons: npm install react-icons
import { DataStore } from '@aws-amplify/datastore';
import { LikedSong } from './models/index';
import { getCurrentUser } from '@aws-amplify/auth';


function SearchComponent() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedTrackData, setSelectedTrackData] = useState(null);
  const [selectedSearchResult, setSelectedSearchResult] = useState(null);
  const inputRef = useRef(null);
  const [dances, setDances] = useState([]);
  const [likedDances, setLikedDances] = useState({});
  const [userId, setUserId] = useState(null);

  const analysisDescriptions = {
    bpm: "Beats Per Minute: The tempo of the track.",
    tact: "Time Signature: The number of beats in each bar.",
    danceability: "A measure from 0.0 to 1.0 indicating how suitable a track is for dancing based on a combination of musical elements.",
    valence: "A measure from 0.0 to 1.0 describing the musical positiveness conveyed by a track. 1=Happy 0=Sad"
  };

  const sortDances = (dances, trackTact, trackBpm) => {
    return dances.sort((a, b) => {
      // First, sort by matching tact
      if (a.tact === trackTact && b.tact !== trackTact) return -1;
      if (b.tact === trackTact && a.tact !== trackTact) return 1;
      
      // If tacts are the same, sort by BPM closeness
      const aBpmDiff = Math.abs(a.bpm - trackBpm);
      const bBpmDiff = Math.abs(b.bpm - trackBpm);
      return aBpmDiff - bBpmDiff;
    });
  };

  useEffect(() => {
    
    const fetchUserAndLikedDances = async () => {
        try {
          const user = await getCurrentUser();
          console.log('Current user:', user);
          setUserId(user.username);
          const fetchedLikedDances = await DataStore.query(LikedSong, c => c.userId('eq', user.username));
          console.log('Fetched liked dances:', fetchedLikedDances);
          const likedDancesMap = fetchedLikedDances.reduce((acc, dance) => {
            acc[dance.songId] = true;
            return acc;
          }, {});
          setLikedDances(likedDancesMap);
        } catch (error) {
          console.error('Error fetching user or liked dances:', error);
        }
      };

      fetchUserAndLikedDances();
    // Fetch dances data
    fetch('/dances.json')
      .then(response => response.json())
      .then(data => setDances(data))
      .catch(error => console.error('Error fetching dances:', error));

    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        performSearch();
      } else {
        setSearchResults([]);
      }
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleLike = async (song, dance) => {
    if (!userId) {
      console.log('User not authenticated');
      return;
    }
  
    const danceId = `${song.id}-${dance.name}`;
    console.log('Handling like for dance:', danceId);
  
    try {
      const isCurrentlyLiked = likedDances[danceId];
      if (isCurrentlyLiked) {
        // Unlike the dance
        const toDelete = await DataStore.query(LikedSong, c => 
          c.and(c => [
            c.userId('eq', userId),
            c.songId('eq', danceId)
          ])
        );
        if (toDelete.length > 0) {
          await DataStore.delete(toDelete[0]);
          console.log('Dance unliked:', danceId);
        }
      } else {
        // Like the dance
        await DataStore.save(new LikedSong({
          userId: userId,
          songId: danceId,
          title: song.title,
          artist: song.artist,
          danceName: dance.name
        }));
        console.log('Dance liked:', danceId);
      }
  
      // Update local state
      setLikedDances(prev => ({
        ...prev,
        [danceId]: !isCurrentlyLiked
      }));
    } catch (error) {
      console.error('Error handling like:', error);
    }
  };


  const performSearch = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://cw69e7ta99.execute-api.eu-central-1.amazonaws.com/Dev/track?search=${searchTerm}`);
      setSearchResults(response.data);
      setShowSuggestions(true);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setShowSuggestions(true);
    setSelectedTrackData(null); // Clear selected track data when search changes
  };

  const handleSuggestionClick = async (result) => {
    setShowSuggestions(false); // Close the search results
    setIsLoading(true);
    setSelectedSearchResult(result);

    try {
      const response = await axios.get(`https://cw69e7ta99.execute-api.eu-central-1.amazonaws.com/Dev/track/analyze/?id=${result.id}`);
      setSelectedTrackData(response.data);
    } catch (error) {
      console.error('Error fetching track details:', error);
      setSelectedTrackData(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowSuggestions(false);
    }
  };

  // Helper function to format percentage
  const formatPercentage = (value) => {
    return (value * 100).toFixed(2) + '%';
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="search-container" ref={inputRef}>
      <h1 className="title">Dance Scout</h1>
      <input
        type="text"
        className="search-input"
        placeholder="Search for tracks..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      {isLoading && <div className="loading">Loading...</div>}
      {showSuggestions && searchResults.length > 0 && (
        <ul className="suggestions-list">
          {searchResults.map((result) => (
            <li 
              key={result.id} 
              onClick={() => handleSuggestionClick(result)}
              className="suggestion-item"
            >
              <span className="suggestion-title">{result.title} - </span>
              <span className="suggestion-artist">{result.artist}</span>
            </li>
          ))}
        </ul>
      )}
      {selectedTrackData && selectedSearchResult && (
        <div className="selected-track-details">
        <h3>{selectedSearchResult.title}</h3>
        <div className="track-info">
          <p><strong>Artist:</strong> {selectedSearchResult.artist}</p>
          <p><strong>Album:</strong> {selectedSearchResult.album}</p>
        </div>
        <div className="track-analysis">
          <div className="analysis-item">
            <span>BPM:</span>
            <span>{selectedTrackData.bpm} (Confidence: {formatPercentage(selectedTrackData.bpm_confidence)})</span>
          </div>
          <div className="analysis-item">
            <span>Takt:</span>
            <span>{selectedTrackData.tact}/4 (Confidence: {formatPercentage(selectedTrackData.tact_confidence)})</span>
          </div>
          <div className="analysis-item">
            <span>Danceability:</span>
            <span>{formatPercentage(selectedTrackData.danceability)}</span>
          </div>
          <div className="analysis-item">
            <span>Valence:</span>
            <span>{formatPercentage(selectedTrackData.valence)}</span>
          </div>
        </div>
        <div className="dances-list">
            <h4>Dances</h4>
            <ul>
            {sortDances(dances, selectedTrackData.tact, selectedTrackData.bpm).map((dance, index) => (
               <li key={index} className="dance-item">
                  <span>{dance.name} ~{dance.bpm}BPM</span>
                  <button 
                    onClick={() => handleLike(selectedSearchResult, dance)}
                    className="like-button"
                    >
                    {likedDances[`${selectedSearchResult.id}-${dance.name}`] ? <FaHeart color="red" /> : <FaRegHeart />}
                  </button>
                </li>
              ))}
            </ul>
          </div>
      </div>
      )}
    </div>
  );
}

export default SearchComponent;
